.custom-dropdown-item {
    transition: all 0.2s ease-in-out !important;
  }
  
  .custom-dropdown-item:hover {
    background-color: #e9ecef !important;
  }
  
  .custom-dropdown-menu {
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .custom-dropdown-toggle:hover {
    opacity: 0.9;
  }