.modal-backdrop {
    z-index: -1;
}
.custom-dropdown-item {
    transition: all 0.2s ease-in-out !important;
  }
  
  .custom-dropdown-item:hover {
    background-color: #e9ecef !important;
  }
  
  .custom-dropdown-menu {
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .custom-dropdown-toggle:hover {
    opacity: 0.9;
  }

  /* src/App.css */

/* Existing styles... */

.modal-backdrop {
  z-index: -1;
}

.custom-dropdown-item {
  transition: all 0.2s ease-in-out !important;
}

.custom-dropdown-item:hover {
  background-color: #e9ecef !important;
}

.custom-dropdown-menu {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.custom-dropdown-toggle:hover {
  opacity: 0.9;
}

/* Fixed Table Styles */

.fixed-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse; /* Ensures borders are collapsed for consistency */
}

.fixed-table th,
.fixed-table td {
  /* Removed truncation styles */
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */

  /* Added scrolling for overflow */
  overflow: auto;
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Break long words */

  padding: 8px; /* Optional: Adjust padding as needed */
  border: 1px solid #dee2e6; /* Optional: Adds borders to table cells */
}

.fixed-table th {
  background-color: #f8f9fa; /* Optional: Adds a background color to header */
  text-align: left; /* Adjust text alignment as needed */
  position: sticky; /* Optional: Fix header row */
  top: 0; /* Position sticky header at the top */
  z-index: 1; /* Ensure header stays above body rows */
}

/* Column Widths */

.fixed-table .id-column {
  width: 40px;
}

.fixed-table .original-file-column {
  width: 190px;
}

.fixed-table .cleaned-file-column {
  width: 190px;
}

.fixed-table .merged-file-column {
  width: 190px;
}

.fixed-table .status-column {
  width: 80px;
}

.fixed-table .created-column {
  width: 100px;
}

.fixed-table .summary-column {
  width: 150px;
}

.fixed-table .action-column {
  width: 80px;
}

/* Optional Enhancements */

/* Hover effect with subtle background change */
.fixed-table tbody tr:hover {
  background-color: #f1f3f5;
}

/* Alternate row coloring */
.fixed-table tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.fixed-table tbody tr:nth-child(even) {
  background-color: #f8f9fa;
}

/* Optional: Fixed Header Row */

/* Already included above in .fixed-table th */

/* Optional: Scrollbar Styling for WebKit Browsers */

.fixed-table th::-webkit-scrollbar,
.fixed-table td::-webkit-scrollbar {
  width: 8px;
}

.fixed-table th::-webkit-scrollbar-track,
.fixed-table td::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.fixed-table th::-webkit-scrollbar-thumb,
.fixed-table td::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.fixed-table th::-webkit-scrollbar-thumb:hover,
.fixed-table td::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}